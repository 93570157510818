import React from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/auth';
import { FaPowerOff } from 'react-icons/fa';
import Theme from 'exalt3d-sublime-ui/dist/utils/theme';
function index({ signOut }) {
  return (
    <FaPowerOff
      className="pointed"
      role="button"
      onClick={signOut}
      size={20}
      color={Theme.sublime.color.mainGold}
    />
  );
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  signOut
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
