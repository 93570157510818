import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import Separator from "exalt3d-sublime-ui/dist/components/Ui/Separator";
import Theme from "exalt3d-sublime-ui/dist/utils/theme";
import { fetchLog } from "../../../store/actions/logs";
import { formatFileSize } from "../../../utils/bytes";

const defaultImageProcess = {
  project_id: null,
  user_id: null,
  at: null,
  folders: [],
  processed: [],
  sizes: [],
  original_size: {
    width: null,
    height: null
  }
};

const ProccededHeader = () => {
  return (
    <div className="flex-row full-width marged-top">
      <span style={{ width: "20%", textAlign: "center" }}>File name</span>
      <span style={{ width: "20%", textAlign: "center" }}>File type</span>
      <span style={{ width: "20%", textAlign: "center" }}>Folder name</span>
      <span style={{ width: "20%", textAlign: "center" }}>Original size</span>
      <span style={{ width: "20%", textAlign: "center" }}>Size</span>
    </div>
  );
};

const ProccededItem = ({ image, isPair }) => {
  return (
    <div
      style={{
        backgroundColor: isPair ? "#fafafa" : "#dfdfdf",
        height: "30px"
      }}
      className="flex-row full-width flex-center-items"
    >
      <span style={{ width: "20%", textAlign: "center" }}>
        {image.file_name}
      </span>
      <span style={{ width: "20%", textAlign: "center" }}>
        {image.file_type}
      </span>
      <span style={{ width: "20%", textAlign: "center" }}>
        {image.folder_name}
      </span>
      <span style={{ width: "20%", textAlign: "center" }}>
        {image.original_size}
      </span>
      <span style={{ width: "20%", textAlign: "center" }}>
        {formatFileSize(image.size, 3)}
      </span>
    </div>
  );
};

function ImagesProcessLogModal({ selectedLog, logType, fetchSelectedLog, t }) {
  const [isOpen, setIsOpen] = useState(false);
  const [imageProcess, setImageProcess] = useState(defaultImageProcess);

  const toggleModal = () => {
    setIsOpen(false);
    setImageProcess({ ...defaultImageProcess });
  };
  useEffect(() => {
    if (selectedLog) {
      fetchSelectedLog(selectedLog, logType)
        .then(response => {
          setIsOpen(true);
          setImageProcess({ ...imageProcess, ...response.data });
          console.log("LOG RESULT", response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [logType, selectedLog]);
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader>{selectedLog}</ModalHeader>
      <ModalBody style={{ overflowWrap: "break-word" }}>
        <div className="marged-top">
          <span>
            {t("logs.datas.projectId", { id: imageProcess.project_id })}
          </span>
        </div>
        <div className="marged-top">
          <span>{t("logs.datas.userId", { id: imageProcess.user_id })}</span>
        </div>
        <div className="marged-top">
          <span>{t("logs.datas.at", { time: imageProcess.at })}</span>
        </div>
        <div className="marged-top">
          <span>
            {t("logs.datas.originalSize", {
              width: imageProcess.original_size
                ? imageProcess.original_size.width
                : null,
              height: imageProcess.original_size
                ? imageProcess.original_size.height
                : null
            })}
          </span>
        </div>
        <div className="marged-top">
          <span>
            {t("logs.datas.resized")}
            <ul>
              {imageProcess.sizes.map(size => (
                <li className="marged-top" key={size}>
                  - {size.join("x")}
                </li>
              ))}
            </ul>
          </span>
        </div>
        <div className="marged-top">
          <span>
            {t("logs.datas.folders")}
            <ul>
              {imageProcess.folders.map(folder => (
                <li className="marged-top" key={folder}>
                  - {folder}
                </li>
              ))}
            </ul>
          </span>
        </div>
        <div className="marged-top">
          <span>
            {t("logs.datas.processed", {
              fileCount: imageProcess.processed.length
            })}
            <ProccededHeader />
            <ul style={{ maxHeight: "500px", overflow: "scroll" }}>
              {imageProcess.processed.map((image, idx) => {
                const isPair = idx % 2 === 0;
                return (
                  <li key={`procceded-${idx}`}>
                    <ProccededItem isPair={isPair} image={image} />
                  </li>
                );
              })}
            </ul>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = { fetchSelectedLog: fetchLog };

export default withTranslation(["admin"])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ImagesProcessLogModal)
);
