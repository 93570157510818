import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../store/actions/auth';
import { Redirect, Link } from 'react-router-dom';
import './SignUpForm.scss';
import Sublime from '../shared/logos/Sublime';
import { withTranslation } from 'react-i18next';
import TextField from 'exalt3d-sublime-ui/dist/components/Inputs/TextField';
import Button from 'exalt3d-sublime-ui/dist/components/Inputs/Button';
import { TiArrowBackOutline } from 'react-icons/ti';
import countryList from 'react-select-country-list';
import AlertMessage from '../shared/messages/Alert';

const requiredFields = [
  'firstname',
  'lastname',
  'email',
  'password',
  'passwordConfirmation'
];

export class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.options = countryList().getData();

    this.state = {
      loading: false,
      errors: [],
      options: this.options,
      userFields: {
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        country: 'FR',
        password: '',
        passwordConfirmation: ''
      }
    };
  }

  handleChange = async event => {
    this.removeError(event.target.name);
    await this.setState({
      ...this.state,
      userFields: {
        ...this.state.userFields,
        [event.target.name]: event.target.value
      }
    });
  };

  validateForm() {
    let errors = [];
    requiredFields.forEach(field => {
      if (this.state.userFields[field].length === 0) {
        errors.push(field);
      } else {
        this.removeError(field);
      }
    });

    if (
      this.state.userFields.password !==
      this.state.userFields.passwordConfirmation
    ) {
      errors.push('password', 'passwordConfirmation');
    }

    this.setState({ errors: [...errors] });
    return errors.length >= 1 ? true : false;
  }

  removeError(field) {
    let { errors } = this.state;
    if (errors.includes(field)) {
      errors.splice(errors.indexOf(field), 1);
    }
    this.setState({ errors: [...errors] });
  }

  signUp = async () => {
    this.setState({ loading: true });
    if (!this.validateForm()) {
      await this.props.signUp(this.state.userFields);
    }
    this.setState({ loading: false });
  };

  render() {
    if (this.props.auth.isConnected && !this.state.loading) {
      if (this.props.user.first_connexion) {
        return <Redirect to="/dashboard/account/offers" />;
      } else {
        return <Redirect to="/dashboard/home" />;
      }
    }
    const { t } = this.props;
    return (
      <div className="signup-container flex-column flex-center flex-center-items full-height-screen">
        <Sublime />
        <div className="welcome-text">
          <h1>{t('title')}</h1>
        </div>
        <div className="details flex-center-items flex-column">
          <p>{t('detail')}</p>
          <span>
            <p>{t('required')}</p>
          </span>
        </div>
        <div>
          <AlertMessage />
        </div>
        <div className="form-fields flex-column flex-center flex-center-items">
          <TextField
            error={this.state.errors.includes('firstname')}
            placeholder={t('firstname')}
            name="firstname"
            value={this.state.userFields.firstname}
            onChange={this.handleChange}
          />
          <TextField
            error={this.state.errors.includes('lastname')}
            placeholder={t('lastname')}
            name="lastname"
            value={this.state.userFields.lastname}
            onChange={this.handleChange}
          />
          <TextField
            error={this.state.errors.includes('email')}
            placeholder={t('email')}
            type="email"
            name="email"
            value={this.state.userFields.email}
            onChange={this.handleChange}
          />
          <TextField
            placeholder={t('company')}
            name="company"
            value={this.state.userFields.company}
            onChange={this.handleChange}
          />
          <select
            name="country"
            onChange={this.handleChange}
            value={this.state.userFields.country}
            className="sublime-input sublime-select"
          >
            {this.state.options.map((country, idx) => {
              return (
                <option value={country.value} key={`country-${idx}`}>
                  {country.label}
                </option>
              );
            })}
          </select>
          <TextField
            type="password"
            error={this.state.errors.includes('password')}
            placeholder={t('password')}
            name="password"
            value={this.state.userFields.password}
            onChange={this.handleChange}
          />
          <TextField
            type="password"
            error={this.state.errors.includes('passwordConfirmation')}
            placeholder={t('passwordConfirmation')}
            name="passwordConfirmation"
            value={this.state.userFields.passwordConfirmation}
            onChange={this.handleChange}
          />
          {this.state.userFields.password !==
            this.state.userFields.passwordConfirmation &&
            this.state.errors.includes('passwordConfirmation') && (
              <p className="sublime-error-text">
                {t('errorMessageNoMatchinPassword')}
              </p>
            )}
          <Button
            disabled={this.state.loading}
            onClick={this.signUp}
            label={t('title')}
          />
        </div>
        <div className="already-have-account">
          <span>
            <Link className="primary-link" to="/">
              <TiArrowBackOutline size="18px" /> {t('login:signIn')}
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {
  signUp
};

// @ts-ignore
export default withTranslation(['signUp', 'login'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUpForm)
);
