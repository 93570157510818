import {
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  SUCCESS_MESSAGE,
  INFO_MESSAGE,
  CLEAR_MESSAGES
} from '../types/app';

const initialState = {
  messages: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ERROR_MESSAGE:
      const errorMessages = covertToArray(payload, 'danger');
      return { ...state, messages: [...errorMessages] };

    case WARNING_MESSAGE:
      const warningMessages = covertToArray(payload, 'warning');
      return { ...state, messages: [...warningMessages] };

    case INFO_MESSAGE:
      const infoMessages = covertToArray(payload, 'info');
      return { ...state, messages: [...infoMessages] };

    case SUCCESS_MESSAGE:
      const successMessages = covertToArray(payload, 'success');
      return { ...state, messages: [...successMessages] };

    case CLEAR_MESSAGES:
      return { ...state, messages: [] };

    default:
      return state;
  }
};

function covertToArray(datas, type) {
  if (Array.isArray(datas)) {
    return datas.map(data => {
      return { message: data, type };
    });
  }
  return [{ message: datas, type }];
}
