import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { signIn } from '../../store/actions/auth';
import Sublime from '../shared/logos/Sublime';
import TextField from 'exalt3d-sublime-ui/dist/components/Inputs/TextField';
import CheckBox from 'exalt3d-sublime-ui/dist/components/Inputs/CheckBox';
import Button from 'exalt3d-sublime-ui/dist/components/Inputs/Button';
import { Link, Redirect } from 'react-router-dom';
import AlertMessage from '../shared/messages/Alert';
import './SignInForm.scss';

export class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      loading: false
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleCheckBoxChange = event => {
    this.setState({ rememberMe: event.target.checked });
  };

  login = async () => {
    const { email, password, rememberMe } = this.state;
    this.setState({ loading: true });
    await this.props.signIn(email, password, rememberMe);
    this.setState({ loading: false });
  };

  render() {
    if (this.props.auth.isConnected && !this.state.loading) {
      if (this.props.user.first_connexion) {
        return <Redirect to="/dashboard/account/offers" />;
      } else {
        return <Redirect to="/dashboard/home" />;
      }
    }
    const { t } = this.props;
    return (
      <div className="signin-container flex-column flex-center flex-center-items full-height-screen">
        <Sublime />
        <div className="welcome-text text-center">
          <h1>{t('title')}</h1>
        </div>
        <div>
          <AlertMessage />
        </div>
        <div className="form-fields flex-column flex-center flex-center-items">
          <TextField
            placeholder={t('emailPlaceholder')}
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextField
            type="password"
            placeholder={t('passwordPlaceholder')}
            name="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <div className="signin-more full-width flex-row flex-around flex-center-items">
            <CheckBox
              name="rememberMe"
              label={t('rememberMe')}
              value={this.state.rememberMe}
              onChange={this.handleCheckBoxChange}
            />
            <Link className="primary-link" to="/forget-password">
              {t('forgetPassword')}
            </Link>
          </div>
        </div>
        <Button
          disabled={this.state.loading}
          onClick={this.login}
          label={t('signIn')}
        />
        <div className="no-account">
          <span>
            {t('noAccount')}{' '}
            <Link className="primary-link" to="/signup">
              {t('signUp:title')}
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {
  signIn
};

// @ts-ignore
export default withTranslation(['login', 'signUp'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignInForm)
);
