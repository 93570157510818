import React from 'react';
import ImagesProcessLogs from './ImagesProcessLogs';

export default function IndexLog() {
  return (
    <div className="layout-margin">
      <ImagesProcessLogs />
    </div>
  );
}
