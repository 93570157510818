import React from 'react';
import { connect } from 'react-redux';
import Avatar from '../../shared/user/Avatar';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './TopBar.scss';
import LogOut from '../../shared/logout';
import Theme from 'exalt3d-sublime-ui/dist/utils/theme';

function TopBar(props) {
  const { user, t } = props;
  return (
    <div className="sublime-navbar full-width flex-row flex-end">
      <div
        className="offer-infos flex-center flex-center-items pointed"
        role="link"
        onClick={() => props.history.push('/dashboard/account/offers')}
      >
        <p style={{ color: Theme.sublime.color.offers[user.offer.name] }}>
          {t('offer', { offer: user.offer.name })}
        </p>
      </div>
      <div
        className="pointed"
        role="link"
        onClick={() => props.history.push('/dashboard/account/profile')}
      >
        <Avatar avatarUrl={user.avatar_url} username={user.nickname} />
      </div>
      <div className="full-height flex-row flex-center-items marged-right">
        <LogOut />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {};

// @ts-ignore
export default withRouter(
  withTranslation(['navbar'])(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TopBar)
  )
);
