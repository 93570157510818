import Theme from 'exalt3d-sublime-ui/dist/utils/theme';
import { buildStyles } from 'react-circular-progressbar';
import { formatFileSize } from './bytes';
export const formatFileTypes = (usedFilesType) => {
  const keys = Object.keys(usedFilesType);
  return {
    labels: keys,
    datasets: [{
      data: keys.map(key => usedFilesType[key]),
      backgroundColor: [
        Theme.sublime.color.mainGreen,
        Theme.sublime.color.mainGold,
        Theme.sublime.color.mainSilver,
        Theme.sublime.color.mainBlack
      ],
      hoverBackgroundColor: [
        Theme.sublime.color.mainGreen,
        Theme.sublime.color.mainGold,
        Theme.sublime.color.mainSilver,
        Theme.sublime.color.mainBlack
      ]
    }]
  };
};

export const buildProjectsUploadsStats = (projects) => {
  return {
    labels: Object.keys(projects), // here is project ids
    datasets: [
      {
        label: 'Total space Mb',
        backgroundColor: Theme.sublime.color.mainGreen,
        borderColor: Theme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: Theme.sublime.color.mainBlack,
        hoverBorderColor: Theme.sublime.color.mainSilver,
        data: Object.keys(projects).map(key => formatFileSize(projects[key].used_disk_space, 3, false))
      },
      {
        label: 'Total Files',
        backgroundColor: Theme.sublime.color.mainGold,
        borderColor: Theme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: Theme.sublime.color.mainBlack,
        hoverBorderColor: Theme.sublime.color.mainSilver,
        data: Object.keys(projects).map(key => projects[key].files)
      },
    ]
  };
};

export function buildCirclePercentStyle(percentage) {
  return buildStyles({
    rotation: 0.25,
    strokeLinecap: 'butt',
    textSize: '16px',
    pathTransitionDuration: 0.5,
    pathColor: Theme.sublime.color.mainGold,
    textColor: Theme.sublime.color.mainGreen,
    trailColor: Theme.sublime.color.mainBlack
  });
}