import React, { Component } from "react";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import { Switch } from "react-router-dom";
import "../assets/styles/templates/dashboard.scss";
// Components
import Home from "../components/private/dashboard/Home";
import LeftMenu from "../components/private/menu/LeftMenu";
import TopBar from "../components/private/navbar/TopBar";
import Profile from "../components/private/dashboard/account/profile/Profile";
import Packages from "../components/private/dashboard/subscriptions/packages";
import Password from "../components/private/dashboard/account/password";
import NewProject from "../components/private/dashboard/projects/New";
import EditProject from "../components/private/dashboard/projects/Edit";
import ProjectsIndex from "../components/private/dashboard/projects/Index";
import Downloads from "../components/private/dashboard/library/uploads";
import UsersManagment from "../components/admin/users/Index";
import ProjectsManagment from "../components/admin/projects/Index";
import PostsManagment from "../components/admin/posts";
import OffersManagment from "../components/admin/offers";
import IndexLogs from "../components/admin/logs";
import AdminStats from "../components/admin/statistics";
import ComingSoon from "../components/shared/comingSoon";
import Stats from "../components/private/dashboard/account/statistics";
import Show from "../components/shared/posts/Show";

export class PrivateRouter extends Component {
  render() {
    const { auth } = this.props;
    const { isConnected, isAdmin } = auth;
    return (
      <div className="sublime-dashboard flex-row">
        <div className="dashboard-menu">
          <LeftMenu />
        </div>
        <div className="dashboard-content">
          <TopBar />
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/home"
              component={Home}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/posts/:id"
              component={Show}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/account/profile"
              component={Profile}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/account/password"
              component={Password}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/account/billing"
              component={ComingSoon}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/account/offers"
              component={Packages}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/account/stats"
              component={Stats}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/projects/availables"
              compProps={{ query: "ready" }}
              component={ProjectsIndex}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/projects/in-progress"
              compProps={{ query: "in_process" }}
              component={ProjectsIndex}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/projects/archives"
              compProps={{ query: "inactive" }}
              component={ProjectsIndex}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/projects/new"
              component={NewProject}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/projects/edit/:id"
              component={EditProject}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/library"
              component={Downloads}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/api/install"
              component={ComingSoon}
              isConnected={isConnected}
            />
            <PrivateRoute
              exact
              path="/dashboard/api/documentation"
              component={ComingSoon}
              isConnected={isConnected}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/users"
              component={UsersManagment}
              isAdmin={isAdmin}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/projects"
              component={ProjectsManagment}
              isAdmin={isAdmin}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/posts"
              component={PostsManagment}
              isAdmin={isAdmin}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/offers"
              component={OffersManagment}
              isAdmin={isAdmin}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/logs"
              component={IndexLogs}
              isAdmin={isAdmin}
            />
            <AdminRoute
              exact
              path="/dashboard/admin/statistics"
              component={AdminStats}
              isAdmin={isAdmin}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouter);
