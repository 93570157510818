import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import ReactTable from "react-table";
import { FaPen, FaTrash, FaFolderOpen, FaCopy } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import SublimeSpinner from "exalt3d-sublime-ui/dist/components/Ui/Spinners/SublimeSpinner";
import confirm from "exalt3d-sublime-ui/src/components/Ui/messageBox/Confirm";
import moment from "moment";
import {
  fetchProjects,
  deleteProject,
  updateProjects,
  duplicateProject
} from "../../../store/actions/sublime-projects";
import AlertMessage from "../../shared/messages/Alert";
export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    this.fetchProjects();
  }

  fetchProjects = async () => {
    await this.props.fetchProjects({ query: { status: "all" } });
    this.setState({ loading: false });
  };

  editProject = projectId => {
    const { history } = this.props;
    history.push(`/dashboard/projects/edit/${projectId}`);
  };

  duplicateProject = async projectId => {
    const { duplicateProject } = this.props;
    await duplicateProject(projectId);
    this.fetchProjects();
  };

  deleteProject = async projectId => {
    const { deleteProject, t } = this.props;
    confirm(
      t("delete.confirm.title", { id: projectId }),
      t("delete.confirm.message"),
      async () => {
        await deleteProject(projectId);
        this.fetchProjects();
      },
      () => {}
    );
  };

  disableProject = async projectId => {
    const { updateProjects, t } = this.props;
    confirm(
      t("update.status.confirm.title", { id: projectId }),
      t("update.status.confirm.message"),
      async () => {
        await updateProjects(projectId, { status: "inactive" });
        this.fetchProjects();
      },
      () => {}
    );
  };

  columnsTemplate = () => {
    const { t } = this.props;

    return [
      { Header: t("fields.id"), accessor: "id", maxWidth: 30 },
      {
        Header: t("fields.createdAt"),
        accessor: "created_at",
        Cell: ({ row }) => {
          return <span>{moment(row.created_at).format("DD/MM/YYYY")}</span>;
        }
      },
      {
        Header: t("fields.updatedAt"),
        accessor: "updated_at",
        Cell: ({ row }) => {
          return <span>{moment(row.updated_at).format("DD/MM/YYYY")}</span>;
        }
      },
      { Header: t("fields.title"), accessor: "name" },
      {
        Header: t("fields.status"),
        accessor: "status",
        Cell: ({ row }) => <span>{t(`status.${row.status}`)}</span>
      },
      { Header: t("fields.author"), id: "author", accessor: d => d.user.email },
      {
        Header: t("fields.configFile"),
        accessor: "config_file_url",
        Cell: ({ row }) => {
          return (
            <>
              {row.config_file_url ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sublime-link"
                  href={row.config_file_url}
                >
                  {t("shared:words.download")}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t("fields.configFile"),
        accessor: "config_file_url",
        Cell: ({ row }) => {
          let indexFile = undefined;
          if (row.config_file_url) {
            indexFile = row.config_file_url.split("/");
            indexFile.pop();
            indexFile.push("index.html");
            indexFile = indexFile.join("/");
          }
          return (
            <>
              {indexFile ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sublime-link"
                  href={indexFile}
                >
                  {t("shared:words.preview")}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t("fields.actions"),
        Cell: ({ row }) => {
          return (
            <ButtonGroup size="sm">
              <Button
                data-tip={t("shared:ressources.edit")}
                color="warning"
                onClick={() => this.editProject(row.id)}
              >
                <FaPen />
              </Button>
              <Button
                data-tip={t("shared:ressources.duplicate")}
                color="success"
                onClick={() => this.duplicateProject(row.id)}
              >
                <FaCopy />
              </Button>
              <Button
                data-tip={t("shared:ressources.delete")}
                color="danger"
                onClick={() => this.deleteProject(row.id)}
              >
                <FaTrash />
              </Button>
              {row.status !== "inactive" && (
                <Button
                  data-tip={t("shared:ressources.disable")}
                  color="info"
                  onClick={() => this.disableProject(row.id)}
                >
                  <FaFolderOpen />
                </Button>
              )}
            </ButtonGroup>
          );
        }
      }
    ];
  };

  render() {
    const { loading } = this.state;
    const { projects } = this.props;
    if (loading) {
      return <SublimeSpinner />;
    }

    return (
      <div>
        <ReactTooltip />
        <div className="flex-center">
          <AlertMessage />
        </div>
        <ReactTable
          className="-striped -highlight"
          defaultPageSize={10}
          expanded={false}
          data={projects}
          columns={this.columnsTemplate()}
          getProps={() => ({ style: { textAlign: "center" } })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.sublimeProjects
});

const mapDispatchToProps = {
  fetchProjects,
  deleteProject,
  updateProjects,
  duplicateProject
};

// @ts-ignore
export default withTranslation(["projects", "shared"])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index)
);
