// @ts-nocheck
import Axios from 'axios';
import baseUrl from '../../api/url';
import {
  FETCH_POSTS,
  FETCH_POSTS_ERROR,
  UPDATE_POST,
  UPDATE_POST_ERROR,
  CREATE_POST,
  CREATE_POST_ERROR
} from '../types/post';
import { buildTokenHeaders } from './auth';

export function fetchPosts(query = null) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: FETCH_POSTS, payload: [...response.data.posts] });
    }
    function onError(error) {
      dispatch({ type: FETCH_POSTS_ERROR, payload: error });
    }

    try {
      const response = await Axios.get(baseUrl.posts, {
        headers: {
          ...buildTokenHeaders()
        },
        params: query
      });
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
}
export function createPost(fields) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: CREATE_POST, payload: response.data.post });
      dispatch(fetchPosts());
    }
    function onError(error) {
      dispatch({ type: CREATE_POST_ERROR, payload: error });
    }

    try {
      let response = null;
      if (fields.newImage) {
        const formData = new FormData();
        Object.keys(fields).forEach(function (key) {
          formData.append(key, fields[key]);
        });
        response = await Axios.post(baseUrl.posts, formData, {
          headers: {
            ...buildTokenHeaders()
          }
        });
      } else {
        response = await Axios.post(baseUrl.posts, { ...fields }, {
          headers: {
            ...buildTokenHeaders()
          }
        });
      }
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
}
export function updatePost(fields, postId) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: UPDATE_POST, payload: response.data.post });
      dispatch(fetchPosts());
    }
    function onError(error) {
      dispatch({ type: UPDATE_POST_ERROR, payload: error });
    }

    try {
      let response = null;
      if (fields.newImage) {
        const formData = new FormData();
        Object.keys(fields).forEach(function (key) {
          formData.append(key, fields[key]);
        });
        response = await Axios.put(`${baseUrl.posts}/${postId}`, formData, {
          headers: {
            ...buildTokenHeaders()
          }
        });
      } else {
        response = await Axios.put(`${baseUrl.posts}/${postId}`, { ...fields }, {
          headers: {
            ...buildTokenHeaders()
          }
        });
      }
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
}
export function deletePost(postId) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch(fetchPosts());
    }
    function onError(error) {
    }

    try {
      const response = await Axios.delete(`${baseUrl.posts}/${postId}`, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
}