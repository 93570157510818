import React, { Suspense } from 'react';
import Router from './routes/Router';
import './App.scss';
import './i18n';

export default function App() {

  return (
    <Suspense fallback="loading">
      <div id="sublime-app">
        <Router />
      </div>
    </Suspense>
  );
};