import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import ReactTable from "react-table";
import { FaPen, FaTrash, FaFolderOpen, FaCopy } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import moment from "moment";
// @ts-ignore
import SublimeSpinner from "exalt3d-sublime-ui/dist/components/Ui/Spinners/SublimeSpinner";
import confirm from "exalt3d-sublime-ui/src/components/Ui/messageBox/Confirm";
import {
  fetchProjects,
  deleteProject,
  updateProjects,
  duplicateProject
} from "../../../../store/actions/sublime-projects";
import AlertMessage from "../../../shared/messages/Alert";

function Index({
  projects,
  t,
  fetchProjects,
  updateProjects,
  deleteProject,
  duplicateProject,
  history,
  query
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchDatas() {
      setLoading(true);
      await fetchProjects({ query: { status: query } });
      setLoading(false);
      ReactTooltip.rebuild();
    }
    fetchDatas();
  }, [query]);

  const editProject = projectId => {
    history.push(`/dashboard/projects/edit/${projectId}`);
  };

  const deleteSelectedProject = async projectId => {
    confirm(
      t("delete.confirm.title", { id: projectId }),
      t("delete.confirm.message"),
      async () => {
        await deleteProject(projectId);
        fetchProjects({ query: { status: query } });
      },
      () => {}
    );
  };

  const duplicateSelectedProject = async projectId => {
    await duplicateProject(projectId);
    fetchProjects({ query: { status: query } });
  };

  const disableProject = async projectId => {
    confirm(
      t("update.status.confirm.title", { id: projectId }),
      t("update.status.confirm.message"),
      async () => {
        await updateProjects(projectId, { status: "inactive" });
        fetchProjects({ query: { status: query } });
      },
      () => {}
    );
  };

  const columnsTemplate = () => {
    return [
      { Header: t("fields.id"), accessor: "id", maxWidth: 30 },
      {
        Header: t("fields.createdAt"),
        accessor: "created_at",
        Cell: ({ row }) => {
          return <span>{moment(row.created_at).format("MMM Do YY")}</span>;
        }
      },
      { Header: t("fields.title"), accessor: "name" },
      {
        Header: t("fields.status"),
        accessor: "status",
        Cell: ({ row }) => <span>{t(`status.${row.status}`)}</span>
      },
      {
        Header: t("fields.configFile"),
        accessor: "config_file_url",
        Cell: ({ row }) => {
          return (
            <>
              {row.config_file_url ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sublime-link"
                  href={row.config_file_url}
                >
                  {t("shared:words.download")}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t("fields.actions"),
        Cell: ({ row }) => {
          return (
            <ButtonGroup size="sm">
              <Button
                data-tip={t("shared:ressources.edit")}
                color="warning"
                onClick={() => editProject(row.id)}
              >
                <FaPen />
              </Button>
              <Button
                data-tip={t("shared:ressources.duplicate")}
                color="success"
                onClick={() => duplicateSelectedProject(row.id)}
              >
                <FaCopy />
              </Button>
              <Button
                data-tip={t("shared:ressources.delete")}
                color="danger"
                onClick={() => deleteSelectedProject(row.id)}
              >
                <FaTrash />
              </Button>
              {row.status !== "inactive" && (
                <Button
                  data-tip={t("shared:ressources.disable")}
                  color="info"
                  onClick={() => disableProject(row.id)}
                >
                  <FaFolderOpen />
                </Button>
              )}
            </ButtonGroup>
          );
        }
      }
    ];
  };

  if (loading) {
    return <SublimeSpinner />;
  }
  return (
    <div>
      <ReactTooltip />
      <div className="flex-center">
        <AlertMessage />
      </div>
      <ReactTable
        className="-striped -highlight"
        defaultPageSize={10}
        expanded={false}
        data={projects}
        columns={columnsTemplate()}
        getProps={() => ({ style: { textAlign: "center" } })}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  projects: state.sublimeProjects
});

const mapDispatchToProps = {
  fetchProjects,
  deleteProject,
  updateProjects,
  duplicateProject
};

export default withTranslation(["projects", "shared"])(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index)
);
