import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { successMessage, errorMessage } from '../../../../../store/actions/app';
import { connect } from 'react-redux';
import Axios from 'axios';
import url from '../../../../../api/url';
import { buildTokenHeaders } from '../../../../../store/actions/auth';

const defaultValues = {
  old_password: '',
  new_password: '',
  new_password_confirmation: ''
};

function EditPassword({ t, successMessage, errorMessage, user, history }) {
  const [values, setValues] = useState(defaultValues);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    if (
      (event.target.name === 'new_password' ||
        event.target.name === 'new_password_confirmation') &&
      error !== null
    ) {
      setError(null);
    }
  }

  function resetPassword(event) {
    event.preventDefault();
    if (
      values.new_password === values.new_password_confirmation &&
      values.new_password.length >= 1
    ) {
      setLoading(true);
      Axios.post(
        url.auth.changePassword,
        { ...values },
        { headers: { ...buildTokenHeaders() } }
      )
        .then(response => {
          setLoading(false);
          successMessage(t('successMessage'));
          setValues(defaultValues);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.status === 401) {
            return errorMessage('Bad old password');
          }
          errorMessage(err.response.data.error || err.response.data);
        });
    } else {
      setError('New passwords no matching !');
    }
  }

  return (
    <Form onSubmit={resetPassword}>
      <Row>
        <Col md={6} sm={12}>
          <FormGroup>
            <Label for="email">{t('email')}</Label>
            <Input
              disabled
              value={user.email}
              type="email"
              name="email"
              id="email"
              placeholder={t('email')}
            />
          </FormGroup>
        </Col>
        <Col md={6} sm={12}>
          <FormGroup>
            <Label for="old_password">{t('oldPassword')}</Label>
            <Input
              value={values.old_password}
              onChange={handleChange}
              type="password"
              name="old_password"
              id="old_password"
              placeholder={t('oldPassword')}
            />
          </FormGroup>
        </Col>
        <Col md={6} sm={12}>
          <FormGroup>
            <Label for="new_password">{t('newPassword')}</Label>
            <Input
              value={values.new_password}
              onChange={handleChange}
              type="password"
              name="new_password"
              id="new_password"
              placeholder={t('newPassword')}
            />
            {error && <p className="sublime-error-text">{error}</p>}
          </FormGroup>
        </Col>
        <Col md={6} sm={12}>
          <FormGroup>
            <Label for="new_password_confirmation">
              {t('newPasswordConfirmation')}
            </Label>
            <Input
              value={values.new_password_confirmation}
              onChange={handleChange}
              type="password"
              name="new_password_confirmation"
              id="new_password_confirmation"
              placeholder={t('newPasswordConfirmation')}
            />
            {error && <p className="sublime-error-text">{error}</p>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <div className="flex-column flex-center-items">
            <FormGroup>
              <Button
                type="submit"
                color="primary"
                disabled={isLoading}
                onClick={resetPassword}
              >
                {t('reset')}
              </Button>
            </FormGroup>
            <div>
              <Button
                color="secondary"
                onClick={() => history.push('/dashboard/account/profile')}
              >
                {t('backToProfile')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  successMessage,
  errorMessage
};

// @ts-ignore
export default withTranslation('passwordReset')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditPassword)
);
