import React from 'react';
import './Offer.scss';
import { withTranslation } from 'react-i18next';
import { Toast, ToastBody, ToastHeader, Table } from 'reactstrap';
import { formatFileSize } from '../../../../../utils/bytes';

function Offer(props) {
  const {
    name,
    options,
    color,
    max_storage_size,
    price,
    projects,
    sessions,
    statistic,
    t
  } = props;
  return (
    <Toast className="sublime-offer" id={`offer-${name}`}>
      <ToastHeader style={{ backgroundColor: color, color: 'white' }}>
        {name.toUpperCase()}
      </ToastHeader>
      <ToastBody>
        <Table striped>
          <tbody>
            <tr>
              <td style={{ color }}>
                {Number.isInteger(price)
                  ? `Out VAT €${price}`
                  : t('package.onDemand.title')}
              </td>
            </tr>
            <tr>
              <td>
                {max_storage_size > 1
                  ? t('package.offers.all.storage', {
                      max_storage: formatFileSize(max_storage_size, 3)
                    })
                  : t('package.onDemand.storage')}
              </td>
            </tr>
            <tr>
              <td>
                {projects >= 1
                  ? t('package.offers.all.projects', { projects })
                  : t('package.onDemand.projects')}
              </td>
            </tr>
            <tr>
              <td>
                {sessions >= 1
                  ? t('package.offers.all.sessions', { sessions })
                  : t('package.onDemand.sessions')}
              </td>
            </tr>
            {options.map((option, idx) => {
              return (
                <tr key={`option-${idx}`}>
                  <td>{option}</td>
                </tr>
              );
            })}
            {statistic && (
              <tr>
                <td>{t('package.offers.all.statistic')}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ToastBody>
    </Toast>
  );
}

export default withTranslation('subscriptions')(Offer);
