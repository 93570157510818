import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Sublime from '../shared/logos/Sublime';
import { Form } from 'reactstrap';
import Button from 'exalt3d-sublime-ui/dist/components/Inputs/Button';
import TextField from 'exalt3d-sublime-ui/dist/components/Inputs/TextField';
import Axios from 'axios';
import url from '../../api/url';
import { successMessage, errorMessage } from '../../store/actions/app';

const defaultStates = {
  email: '',
  password: '',
  password_confirmation: '',
  token: '',
  reseted: false,
  loading: false,
  error: false
};

class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = defaultStates;
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('reset_token');
    if (token) {
      this.setState({ token, reseted: true });
    }
  }


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.error) {
      this.setState({ error: false });
    }
  }

  requestReset = (event) => {
    event.preventDefault();
    if (this.state.email.length >= 1) {
      Axios.post(url.auth.resetPasswordRequest, { email: this.state.email })
        .then(response => {
          this.props.successMessage(this.props.t('successMessageRequest'));
        })
        .catch(err => {
          this.props.errorMessage(this.props.t('errorMessageRequest'));
        });
    }
  }

  changePassword = (event) => {
    event.preventDefault();
    if (this.state.password === this.state.password_confirmation && this.state.password.length >= 1) {
      Axios.post(url.auth.resetPassword, { ...this.state })
        .then(response => {
          this.props.successMessage(this.props.t('successMessageReseted'));
          this.props.history.push('/');
        })
        .catch(err => {
          this.props.errorMessage(err.response.data.error);
        });
    } else {
      this.setState({ ...this.state, error: true });
    }
  }

  requestForm = () => {
    const { t } = this.props;
    return (
      <Form onSubmit={this.requestReset}>
        <TextField type="email" placeholder={t('email')} name="email" value={this.state.email} onChange={this.handleChange} />
        <Button type="submit" onClick={this.requestReset} disabled={this.state.loading} label={t('reset')} />
      </Form>
    );
  }

  resetForm = () => {
    const { t } = this.props;
    return (
      <Form onSubmit={this.changePassword}>
        <div className="flex-column">
          <TextField type="password" placeholder={t('password')} name="password" value={this.state.password} onChange={this.handleChange} />
          {this.state.error && <p className="sublime-error-text">{t('errorMessageNoMatchinPassword')}</p>}
          <TextField type="password" placeholder={t('passwordConfirmation')} name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} />
          {this.state.error && <p className="sublime-error-text">{t('errorMessageNoMatchinPassword')}</p>}
          <Button type="submit" onClick={this.changePassword} disabled={this.state.loading} label={t('update')} />
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div className="full-height-screen flex-column flex-center flex-center-items">
        <div className="flex-center flex-center-items">
          <Sublime />
        </div>
        <div className="sublime-forget-password-form-container">
          {
            this.state.reseted
              ?
              this.resetForm()
              :
              this.requestForm()
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  successMessage,
  errorMessage
};

// @ts-ignore
export default withTranslation('forgetPassword')(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));

