import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { signOut } from '../../../store/actions/auth';
import { fetchPosts } from '../../../store/actions/posts';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import Card from '../../shared/posts/card';

import './Home.scss';
import SublimeLogo from '../../shared/logos/Sublime';

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      post: { id: null, title: '', body: '' }
    };
  }

  componentDidMount() {
    const { fetchPosts } = this.props;
    fetchPosts({ query: { status: 'published' } });
  }

  toggleModal = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { isOpen, post } = this.state;
    const { posts } = this.props;
    return (
      <Container className="full-height flex-column flex-center padded-bottom">
        <div className="sublime-posts-title text-center">
          <SublimeLogo />
          <h1 className="sublime-gold">Sublime news</h1>
        </div>
        <Row className="marged-top">
          <Col xs={12} md={12}>
            <Container>
              <Row>
                {posts.length >= 1 ? (
                  posts.map(post => {
                    return (
                      <LazyLoadComponent key={`post-${post.id}`}>
                        <Col className="flex-center" md={3} xs={12}>
                          <Card {...post} />
                        </Col>
                      </LazyLoadComponent>
                    );
                  })
                ) : (
                  <p>No post at this time !</p>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
        <Modal centered isOpen={isOpen} toggle={this.toggleModal}>
          <ModalBody
            dangerouslySetInnerHTML={{ __html: post.body }}
          ></ModalBody>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

const mapDispatchToProps = {
  signOut,
  fetchPosts
};

// @ts-ignore
export default withTranslation(['dashboard'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
