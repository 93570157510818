// @ts-nocheck
import React from 'react';
import store from '../../../../store';
import Configurator from 'sublime-configurator';

export default function Edit(props) {
  const { match } = props;
  const { id } = match.params;
  return (
    <div style={{ height: 'calc(100vh - 80px)', overflow: 'scroll' }}>
      <Configurator
        store={store}
        tokens={store.getState().auth}
        uploaderToken={store.getState().user.uploader_token}
        projectId={id}
      />
    </div>
  );
};
