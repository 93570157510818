import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toggleSubMenu, setActiveSubMenu } from './utils';
import confirmBox from 'exalt3d-sublime-ui/src/components/Ui/messageBox/Confirm';
import './MenuItem.scss';
function MenuItem({ disabled = false, label = '', showLabel = true, path = '', confirm = false, icon, active = false, setActiveMenu, options = [], history, toggleTopLevel, t }) {
  const className = active ? 'sublime-menu-item-active' : '';
  const labelClassName = showLabel ? 'sublime-menu-label' : 'sublime-menu-label-hidden';

  function toggleActiveItem() {
    if (options.length === 0) { // if no options, item is a link (submenu)
      history.push(`/dashboard/${path}`);
      toggleSubMenu(true);
    } else {
      history.push(`/dashboard/${path}/${options[0].path}`);
      toggleSubMenu();
    }
    setActiveMenu(path);
  }
  return (
    <div className="sublime-menu-link">
      <div className={`${className} sublime-menu-item`}>
        <div onClick={() => {
          if (!disabled) {
            toggleActiveItem();
          }
          if (disabled) {
            toggleTopLevel();
            toggleActiveItem();
          }
        }} className="sublime-menu-label flex-center-items">
          {icon}
          <span className={labelClassName}>{label}</span>
        </div>
        {
          options.length >= 1 &&
          <ul className="sublime-submenu">
            {
              options.map((option, idx) => {
                return <li onClick={setActiveSubMenu} key={`menu-item${idx}`}>
                  <Link
                    onClick={(e) => {
                      if (option.confirm) {
                        e.preventDefault();
                        confirmBox(
                          t('create.confirm.title'),
                          t('create.confirm.message'),
                          () => { history.push(`/dashboard/${path}/${option.path}`); }, // Confirmed
                          () => { } // Canceled
                        );
                      }
                    }}
                    to={`/dashboard/${path}/${option.path}`}
                  >
                    {option.label}
                  </Link>
                </li>;
              })
            }
          </ul>
        }
      </div>
    </div>
  );
}

// @ts-ignore
export default withTranslation(['projects'])(withRouter(MenuItem));