// @ts-nocheck
import Axios from 'axios';
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_ERROR,
  EDIT_PROJECT_ERROR,
  DELETE_PROJECT_ERROR,
  DUPLICATE_PROJECT_ERROR
} from '../types/sublime-projects';
import baseUrl from '../../api/url';
import { buildTokenHeaders } from './auth';
import { successMessage, errorMessage } from './app';
import i18n from '../../i18n';

export function fetchProjects(query = undefined) {
  return async function (dispatch, getState) {
    function onSucces(response) {
      dispatch({ type: FETCH_PROJECTS, payload: response.data.projects });
      return true;
    }
    function onError(error) {
      dispatch({ type: FETCH_PROJECTS_ERROR, payload: error });
      return false;
    }
    try {
      const reponse = await Axios.get(`${baseUrl.projects}`, {
        headers: {
          ...buildTokenHeaders()
        },
        params: query ? { ...query } : undefined
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function updateProjects(projectId, fields = {}) {
  return async function (dispatch, getState) {
    function onSucces(response) {
      // dispatch(fetchProjects());
      return true;
    }
    function onError(error) {
      dispatch({ type: EDIT_PROJECT_ERROR, payload: error });
      return false;
    }
    try {
      const reponse = await Axios.put(`${baseUrl.projects}/${projectId}`, { ...fields }, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function duplicateProject(projectId) {
  return async function (dispatch, getState) {
    function onSucces(response) {
      // dispatch(fetchProjects());
      dispatch(successMessage(i18n.store.data[i18n.language].projects.duplicate.message.success))
      return true;
    }
    function onError(error) {
      dispatch({ type: DUPLICATE_PROJECT_ERROR, payload: error });
      dispatch(errorMessage(i18n.store.data[i18n.language].projects.duplicate.message.error))
      return false;
    }
    try {
      const reponse = await Axios.post(`${baseUrl.projects}/${projectId}/duplicate`, {}, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function deleteProject(projectId) {
  return async function (dispatch, getState) {
    function onSucces(response) {
      // dispatch(fetchProjects());
      return true;
    }
    function onError(error) {
      dispatch({ type: DELETE_PROJECT_ERROR, payload: error });
      return false;
    }
    try {
      const reponse = await Axios.delete(`${baseUrl.projects}/${projectId}`, { headers: { ...buildTokenHeaders() } });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}