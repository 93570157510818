import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleMenu, toggleSubMenu, setActiveSubMenu } from './utils';
import MenuItem from './MenuItem';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { IoIosStats } from 'react-icons/io';
import {
  MdClose,
  MdMenu,
  MdAccountBalance,
  MdSubscriptions,
  MdLibraryBooks
} from 'react-icons/md';
import { FaProjectDiagram, FaNetworkWired, FaUsersCog } from 'react-icons/fa';
import subMenus from './subMenu';
import './LeftMenu.scss';

const iconSize = '22px';
export class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeMenu: 'home',
      showMenuLabel: true
    };
  }

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      showMenuLabel: !this.state.showMenuLabel
    });
    toggleMenu();
    toggleSubMenu(true);
  };

  setActiveMenu = async item => {
    await this.setState({ activeMenu: item });
  };

  // used for toggle active menu on all routes changes, but it refresh all component
  activeMenuByLocation = () => {
    const { pathname } = window.location;
    const dashboardUrl = pathname.split(/dashboard/)[1];
    if (dashboardUrl) {
      // used only on dashboard
      const currentItem = dashboardUrl.split('/')[1];
      if (this.state.activeMenu !== currentItem) {
        this.setActiveMenu(currentItem);
        toggleSubMenu();
      }
      setActiveSubMenu();
    }
  };

  componentDidMount() {
    this.activeMenuByLocation();
    const { history } = this.props;
    history.listen((location, action) => {
      this.activeMenuByLocation();
    });
  }

  render() {
    const { isOpen, activeMenu, showMenuLabel } = this.state;
    const { t } = this.props;
    const { auth } = this.props;
    const { isAdmin, isConnected } = auth;

    const menuIcon = isOpen ? (
      <MdClose onClick={this.toggleMenu} size="24px" color="white" />
    ) : (
      <MdMenu onClick={this.toggleMenu} size="24px" color="white" />
    );
    const dashboardIcon = <IoIosStats size={iconSize} />;
    const accountIcon = <MdAccountBalance size={iconSize} />;
    const subscriptionIcon = <MdSubscriptions size={iconSize} />;
    const projectsIcon = <FaProjectDiagram size={iconSize} />;
    const libraryIcon = <MdLibraryBooks size={iconSize} />;
    const apiIcon = <FaNetworkWired size={iconSize} />;
    const adminIcon = <FaUsersCog size={iconSize} />;
    return (
      <div id="sublime-menu" className="sublime-menu">
        <div className="close-menu full-width flex-row flex-end">
          {menuIcon}
        </div>
        <div className="sublime-menu-items">
          <MenuItem
            toggleTopLevel={this.toggleMenu}
            disabled={!isOpen}
            active={activeMenu === 'home'}
            setActiveMenu={this.setActiveMenu}
            label={t('menu.dashboard')}
            path="home"
            showLabel={showMenuLabel}
            icon={dashboardIcon}
          />
          <MenuItem
            toggleTopLevel={this.toggleMenu}
            disabled={!isOpen}
            active={activeMenu === 'account'}
            setActiveMenu={this.setActiveMenu}
            label={t('menu.account')}
            path="account"
            showLabel={showMenuLabel}
            options={subMenus.account}
            icon={accountIcon}
          />
          <MenuItem
            toggleTopLevel={this.toggleMenu}
            disabled={!isOpen}
            active={activeMenu === 'projects'}
            setActiveMenu={this.setActiveMenu}
            label={t('menu.projects')}
            path="projects"
            showLabel={showMenuLabel}
            options={subMenus.projects}
            icon={projectsIcon}
          />
          <MenuItem
            toggleTopLevel={this.toggleMenu}
            disabled={!isOpen}
            active={activeMenu === 'library'}
            setActiveMenu={this.setActiveMenu}
            label={t('menu.library')}
            path="library"
            showLabel={showMenuLabel}
            icon={libraryIcon}
          />
          <MenuItem
            toggleTopLevel={this.toggleMenu}
            disabled={!isOpen}
            active={activeMenu === 'api'}
            setActiveMenu={this.setActiveMenu}
            label={t('menu.api')}
            path="api"
            showLabel={showMenuLabel}
            options={subMenus.api}
            icon={apiIcon}
          />
          {isConnected && isAdmin && (
            <MenuItem
              toggleTopLevel={this.toggleMenu}
              disabled={!isOpen}
              active={activeMenu === 'admin'}
              setActiveMenu={this.setActiveMenu}
              label={t('menu.admin')}
              path="admin"
              showLabel={showMenuLabel}
              options={subMenus.admin}
              icon={adminIcon}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

// @ts-ignore
export default withRouter(
  withTranslation('dashboard')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LeftMenu)
  )
);
