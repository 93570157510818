import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Toast,
  ToastBody,
  ToastHeader,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import './Profile.scss';
import {
  fetchCurrentUser,
  updateUser
} from '../../../../../store/actions/user';
import Avatar from '../../../../shared/user/Avatar';
import { keepOnlyKeys } from '../../../../../utils/objects';
import AlertMessage from '../../../../shared/messages/Alert';
import SublimeHeader from '../../../../shared/SublimeHeader';
export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        avatar_url: ''
      }
    };
  }

  componentDidMount = async () => {
    const { user, fetchCurrentUser } = this.props;
    await fetchCurrentUser();
    this.setState({
      ...this.state,
      user: { ...keepOnlyKeys(user, Object.keys(this.state.user)) }
    });
  };

  handleChange = event => {
    this.setState({
      ...this.state,
      user: { ...this.state.user, [event.target.name]: event.target.value }
    });
  };

  previewAvatar = event => {
    const avatars = document.getElementsByClassName('sublime-avatar');
    for (let i = 0; i < avatars.length; i++) {
      avatars[i].src = window.URL.createObjectURL(event.target.files[0]);
    }
    this.setState({
      ...this.state,
      user: { ...this.state.user, avatar: event.target.files[0] }
    });
  };

  submitForm = event => {
    event.preventDefault();
    this.props.updateUser(this.state.user, this.props.user.id);
  };

  render() {
    const { t, history } = this.props;
    return (
      <div className="layout-margin">
        <Toast>
          <ToastHeader>
            <SublimeHeader title={t('profile.title')} />
          </ToastHeader>
          <ToastBody>
            <div className="flex-center marged-bottom">
              <AlertMessage />
            </div>
            <Form onSubmit={this.submitForm}>
              <Row>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label for="firstname">{t('profile.firstname')}</Label>
                    <Input
                      value={this.state.user.firstname}
                      onChange={this.handleChange}
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder={t('profile.firstname')}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label for="lastname">{t('profile.lastname')}</Label>
                    <Input
                      value={this.state.user.lastname}
                      onChange={this.handleChange}
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder={t('profile.lastname')}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label for="company">{t('profile.company')}</Label>
                    <Input
                      value={this.state.user.company}
                      onChange={this.handleChange}
                      type="text"
                      name="company"
                      id="company"
                      placeholder={t('profile.company')}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label for="email">{t('profile.email')}</Label>
                    <Input
                      value={this.state.user.email}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t('profile.email')}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <FormGroup>
                    <div className="flex-column">
                      <Label for="avatar">{t('profile.avatar')}</Label>
                      <div
                        onClick={() =>
                          document.getElementById('avatar').click()
                        }
                        className="marged-bottom pointed"
                      >
                        <Avatar
                          avatarUrl={this.state.user.avatar_url}
                          username={this.state.user.nickname}
                        />
                      </div>
                    </div>
                    <Input
                      style={{ display: 'none' }}
                      onChange={this.previewAvatar}
                      type="file"
                      name="avatar"
                      id="avatar"
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <div className="flex-column flex-center-items">
                    <FormGroup>
                      <Button type="submit" color="primary">
                        {t('profile.update')}
                      </Button>
                    </FormGroup>
                    <div>
                      <Button
                        color="secondary"
                        onClick={() =>
                          history.push('/dashboard/account/password')
                        }
                      >
                        {t('profile.changePassword')}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  fetchCurrentUser,
  updateUser
};

// @ts-ignore
export default withTranslation('account')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
);
