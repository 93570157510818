import React from "react";
import Theme from "exalt3d-sublime-ui/dist/utils/theme";

import "./ProcessStatus.scss";

export default function ProcessStatus({ isRunning }) {
  const color = isRunning
    ? Theme.sublime.color.mainGreen
    : Theme.sublime.color.mainRed;
  const text = isRunning
    ? "Process images is running"
    : "Process images is stoped";
  return (
    <span className="flex-row">
      <div
        style={{ backgroundColor: color }}
        className="sublime-status-pill"
      ></div>
      <span className="marged-left">{text}</span>
    </span>
  );
}
