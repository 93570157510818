import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchOffers, updateOffer } from '../../../store/actions/offers';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap';
import ReactTable from 'react-table';
// @ts-ignore
import SublimeSwitch from 'exalt3d-sublime-ui/dist/components/Inputs/SublimeSwitch';
import { Multiselect } from 'react-widgets';
import { FaPen } from 'react-icons/fa';
import { formatFileSize } from '../../../utils/bytes';

const defaultOffer = {
  id: undefined,
  name: '',
  price: 0,
  max_storage_size: 0,
  sessions: 0,
  projects: 0,
  statistic: false,
  options: [],
  color: '',
  is_active: false
};

export class OfferManagment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      offer: defaultOffer,
      allOptions: []
    };
  }

  async componentDidMount() {
    const { fetchOffers } = this.props;
    await fetchOffers();
    // dont moove this.props.offers to destructurate it need to await the fetch
    const allOptions = [...new Set(this.props.offers.map(o => o.options).flat())];
    this.setState({ allOptions });
  }


  toggleModal = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  handleChange = (event) => {
    event.persist();
    this.setState(prevState => (
      {
        ...prevState,
        offer: {
          ...prevState.offer,
          [event.target.name]: event.target.value
        }
      }
    ));
  }

  submitForm = async (e) => {
    e.preventDefault();
    const { offer } = this.state;
    const { updateOffer } = this.props;
    await updateOffer(offer, offer.id);
    this.resetDefault();
  }

  resetDefault = () => {
    this.setState({ offer: defaultOffer, isOpen: false });
  }
  columnsTemplate = () => {
    const { t } = this.props;
    return [
      { Header: t('offers.fields.id'), accessor: 'id', maxWidth: 30 },
      { Header: t('offers.fields.name'), accessor: 'name' },
      {
        Header: t('shared:words.actions'), maxWidth: 150, Cell: ({ row }) => {
          return (
            <ButtonGroup size="sm">
              <Button
                data-tip={t('shared:ressources.delete')}
                onClick={() => {
                  this.setState({ isOpen: true, offer: { ...row._original } });
                }}
                color="warning">
                <FaPen />
              </Button>
            </ButtonGroup>
          );
        }
      },
    ];
  }

  render() {
    const { isOpen, offer, allOptions } = this.state;
    const { offers, t } = this.props;
    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          defaultPageSize={10}
          expanded={false}
          data={offers}
          columns={this.columnsTemplate()}
        />
        <Modal size="lg" centered toggle={this.toggleModal} isOpen={isOpen}>
          <ModalHeader>
            {offer.name}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Form onSubmit={this.submitForm}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label for="name">{t('offers.fields.name')}</Label>
                      <Input
                        name="name"
                        id="name"
                        value={offer.name}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.name')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Label for="price">{t('offers.fields.price')}</Label>
                      <Input
                        name="price"
                        type="number"
                        id="price"
                        value={offer.price || undefined}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.price')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Label for="max_storage_size">{t('offers.fields.max_storage_size', { formatedSize: formatFileSize(offer.max_storage_size, 3) })}</Label>
                      <Input
                        name="max_storage_size"
                        type="number"
                        id="max_storage_size"
                        value={offer.max_storage_size}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.max_storage_size')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Label for="sessions">{t('offers.fields.sessions')}</Label>
                      <Input
                        name="sessions"
                        type="number"
                        id="sessions"
                        value={offer.sessions}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.sessions')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Label for="projects">{t('offers.fields.projects')}</Label>
                      <Input
                        name="projects"
                        type="number"
                        id="projects"
                        value={offer.projects}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.projects')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup className="flex-row flex-center-items">
                      <Label for="statistic">{t('offers.fields.statistic')}</Label>
                      <SublimeSwitch
                        onChange={(event) => {
                          this.setState(prevState => (
                            {
                              ...prevState,
                              offer: {
                                ...prevState.offer,
                                statistic: event.target.checked
                              }
                            }
                          ));
                        }}
                        checked={offer.statistic}
                        name="statistic"
                        id="statistic"
                        className="marged-left"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Label for="color">{t('offers.fields.color')}</Label>
                      <Input
                        name="color"
                        type="color"
                        id="color"
                        value={offer.color}
                        onChange={this.handleChange}
                        placeholder={t('offers.fields.color')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label for="options">{t('offers.fields.options')}</Label>
                      <Multiselect
                        allowCreate="onFilter"
                        data={allOptions}
                        defaultValue={offer.options}
                        onChange={(selected) => {
                          this.setState(prevState => (
                            {
                              ...prevState,
                              offer: {
                                ...prevState.offer,
                                options: [...selected]
                              }
                            }
                          ));
                        }}
                        onCreate={(newItem, meta) => {
                          this.setState(prevState => {
                            const { options } = offer;
                            options.push(newItem); // push in selected
                            allOptions.push(newItem); // push in all propositions
                            return (
                              {
                                ...prevState,
                                offer: {
                                  ...offer,
                                  options: [...options]
                                },
                                allOptions: [...allOptions]
                              }
                            );
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup className="flex-row flex-center-items">
                      <Label for="is_active">{t('offers.fields.is_active')}</Label>
                      <SublimeSwitch
                        onChange={(event) => {
                          this.setState(prevState => (
                            {
                              ...prevState,
                              offer: {
                                ...prevState.offer,
                                is_active: event.target.checked
                              }
                            }
                          ));
                        }}
                        checked={offer.is_active}
                        name="is_active"
                        id="is_active"
                        className="marged-left"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="flex-row flex-end marged-top">
                      <Button type="submit">Valid</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  offers: state.offers
});

const mapDispatchToProps = {
  fetchOffers,
  updateOffer
};

// @ts-ignore
export default withTranslation(['admin', 'shared'])(connect(mapStateToProps, mapDispatchToProps)(OfferManagment));