// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchProjects } from '../../../../../store/actions/sublime-projects';
import { fetchProjectsFolders, fetchProjectFolders } from './fetcher';
import { Container, Row, Col } from 'reactstrap';
import FileItem from './FileItem';
import SublimeSpinner from 'exalt3d-sublime-ui/dist/components/Ui/Spinners/SublimeSpinner';
import NoContent from './NoContent';

const defaultFolder = { files: [] };
export class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectsFolders: [],
      filesAndFolders: [],
      currentPath: [],
      root: true,
      selectedFolder: defaultFolder,
      loading: true
    };
  }

  async componentDidMount() {
    const { fetchProjects } = this.props;
    await fetchProjects();
    try {
      const response = await fetchProjectsFolders();
      await this.setState({ projectsFolders: [...response.data.folders] });
      this.mergeProjects();
    } catch (err) {
      console.log('Error while fetching files and folders');
      this.setState({ filesAndFolders: [], loading: true });
    }
  }

  componentWillReceiveProps() {
    this.mergeProjects();
  }

  mergeProjects() {
    const { projectsFolders, filesAndFolders } = this.state;
    const { sublimeProjects } = this.props;
    const mergedDatas = sublimeProjects
      .map((p, i) => {
        const folder = projectsFolders.find(f => f.id === p.id);
        return folder ? { ...folder, ...p } : p;
      })
      .filter(p => Object.keys(p).includes('files'));
    if (
      mergedDatas.length >= 1 &&
      mergedDatas[0].files &&
      filesAndFolders.length < 1
    ) {
      this.setState({ filesAndFolders: mergedDatas, loading: false });
    } else {
      this.setState({ filesAndFolders: [], loading: false });
    }
  }

  render() {
    const {
      filesAndFolders,
      root,
      selectedFolder,
      loading,
      currentPath
    } = this.state;
    if (loading) {
      return <SublimeSpinner />;
    }
    if (filesAndFolders.length < 1) {
      return <NoContent />;
    }
    return (
      <Container className="layout-margin">
        <Row>
          <Col xs={12} md={12}>
            {!root && (
              <span
                role="button"
                className="pointed sublime-gold"
                onClick={() => {
                  this.setState(prevState => {
                    currentPath.pop();
                    const newFolder =
                      currentPath.length < 1
                        ? defaultFolder
                        : currentPath[currentPath.length - 1];
                    return {
                      root: currentPath.length < 1,
                      selectedFolder: {
                        ...prevState.selectedFolder,
                        ...newFolder
                      },
                      currentPath: [...currentPath]
                    };
                  });
                }}
              >
                {`<- Back --- / ${
                  currentPath.length < 1
                    ? ''
                    : currentPath
                        .map(f =>
                          selectedFolder.named_nomenclatures[f.name]
                            ? selectedFolder.named_nomenclatures[f.name]
                            : f.name
                        )
                        .join(' / ')
                }`}
              </span>
            )}
            <div style={{ overflow: 'scroll' }} className="flex-row flex-wrap">
              {root &&
                filesAndFolders.map(faf => {
                  return (
                    <FileItem
                      key={faf.id}
                      isFolder
                      folder={faf}
                      name={faf.name}
                      onClick={() =>
                        this.setState(prevState => ({
                          root: false,
                          selectedFolder: faf,
                          currentPath: [faf]
                        }))
                      }
                    />
                  );
                })}
              {!root && selectedFolder && (
                <div className="flex-row flex-wrap">
                  {selectedFolder.files.length >= 1 &&
                    selectedFolder.files.map(fafName => {
                      const isFolder = !fafName.includes('.');
                      return (
                        <FileItem
                          key={fafName}
                          isFolder={isFolder}
                          folder={selectedFolder}
                          name={fafName}
                          onClick={async () => {
                            if (isFolder) {
                              this.setState({ loading: true });
                              const response = await fetchProjectFolders(
                                selectedFolder,
                                selectedFolder.isSizeFolder
                                  ? `${selectedFolder.name}/${fafName}`
                                  : fafName
                              );
                              currentPath.push(response.data.folder);
                              this.setState(prevState => ({
                                root: false,
                                loading: false,
                                selectedFolder: {
                                  ...prevState.selectedFolder,
                                  ...response.data.folder
                                },
                                currentPath: [...currentPath]
                              }));
                            }
                          }}
                        />
                      );
                    })}
                  {selectedFolder.files.length < 1 && (
                    <p className="marged-top">No files found</p>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  sublimeProjects: state.sublimeProjects
});

const mapDispatchToProps = {
  fetchProjects
};

export default withTranslation(['projects'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(index)
);
