// @ts-nocheck
import React from 'react';
import './index.scss';
import sublimeLogo from '../../../../assets/images/logos/logo-exalt3d-sublime.png';
import moment from 'moment';
import Theme from 'exalt3d-sublime-ui/dist/utils/theme';
import { withRouter } from 'react-router-dom';
function index({ id, title, subtitle, user, image_url, created_at, history }) {
  const url = image_url && image_url.length > 1 ? image_url : sublimeLogo;
  return (
    <div
      className="sublime-post-card pointed"
      role="link"
      onClick={() => history.push(`/dashboard/posts/${id}`)}
    >
      <div style={{ backgroundImage: `url(${url})` }} className="sublime-post-card-image">
      </div>
      <div className="container marged-top sublime-post-card-body">
        <span
          style={{ color: Theme.sublime.color.mainSilverRgba(0.5) }}
        >{moment(created_at).startOf('hour').fromNow()}</span>
        <div className="marged-top full-height">
          <h4
            style={{ color: Theme.sublime.color.mainGreen }}
          >{title}</h4>
          <p
            style={{ color: Theme.sublime.color.mainBlack }}
          >{subtitle}</p>
          <span style={{ position: 'absolute', bottom: '25px', right: '25px', opacity: 0.5 }}>
            by <span className="sublime-gold">{`${user.firstname} ${user.lastname.toUpperCase()}`}</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default withRouter(index);