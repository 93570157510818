import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import SublimeSpinner from 'exalt3d-sublime-ui/dist/components/Ui/Spinners/SublimeSpinner';
export class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      post: null
    };
  }

  componentDidMount() {
    const { match, posts } = this.props;
    const { id } = match.params;
    const post = posts.find(post => post.id === parseInt(id));
    console.log(post);
    this.setState({ loading: post === undefined, post });
  }

  render() {
    const { loading, post } = this.state;
    const { history } = this.props;
    return (
      <Container className="marged-top">
        <div
          role="link"
          className="pointed"
          onClick={() => history.push('/dashboard/home')}
        >Back</div>
        <div className="marged-top">
          {
            loading
              ?
              <SublimeSpinner />
              :
              <div>
                <h2 className="text-center">{post.title}</h2>
                <h6 className="text-center">{post.subtitle}</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: post.body }}
                  className="marged-top text-center"
                ></div>
              </div>
          }
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.posts
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Show);
