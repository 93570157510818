import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  fetchUsers,
  updateUser,
  fetchRoles
} from "../../../store/actions/user";
import { Modal, ModalBody } from "reactstrap";
import ReactTable from "react-table";
import { SelectList } from "react-widgets";
import sublimeConfirm from "exalt3d-sublime-ui/src/components/Ui/messageBox/Confirm";
// @ts-ignore
import SublimeSwitch from "exalt3d-sublime-ui/dist/components/Inputs/SublimeSwitch";
import Stat from "../../private/dashboard/account/statistics";

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      makeConfirm: true,
      stat: {
        currentSelected: null,
        modal: false
      }
    };
  }

  componentDidMount() {
    this.fetchUsers();
    this.props
      .fetchRoles()
      .then(response => {
        this.setState({ roles: [...response.data.roles] });
      })
      .catch(error => {
        console.log(error);
      });
  }

  fetchUsers = () => {
    const { fetchUsers } = this.props;
    fetchUsers();
  };

  updateUser = (values, userId) => {
    const { makeConfirm } = this.state;
    const { updateUser, t } = this.props;
    if (makeConfirm) {
      sublimeConfirm(
        t("users.update.confirm.title", { id: userId }),
        t("users.update.confirm.message", { values: JSON.stringify(values) }),
        () => {
          updateUser(values, userId);
        },
        () => {
          this.fetchUsers();
        }
      );
    } else {
      updateUser(values, userId);
    }
  };

  editableContent = (field, row) => {
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onInput={e => {
          e.persist();
          clearTimeout(this.updateTimeout);
          this.updateTimeout = setTimeout(() => {
            // @ts-ignore
            this.updateUser({ [field]: e.target.innerHTML }, row._original.id);
          }, 1000);
        }}
        dangerouslySetInnerHTML={{
          __html: row._original[field]
        }}
      />
    );
  };

  editableRolesContent = row => {
    const { roles } = this.state;
    return (
      <div>
        <SelectList
          multiple
          textField="label"
          valueField="value"
          data={roles.map(r => ({ label: r.name, value: r.id }))}
          defaultValue={row._original.roles.map(r => r.id)}
          onChange={selected => {
            const selectedRoles = selected.map(op => ({
              id: parseInt(op.value)
            }));
            this.updateUser({ roles: selectedRoles }, row._original.id);
          }}
        />
      </div>
    );
  };

  columnsTemplate() {
    const { t } = this.props;
    return [
      { Header: t("users.fields.id"), accessor: "id", maxWidth: 30 },
      {
        Header: t("users.fields.email"),
        Cell: ({ row }) => this.editableContent("email", row)
      },
      {
        Header: t("users.fields.roles"),
        width: 150,
        Cell: ({ row }) => this.editableRolesContent(row)
      },
      {
        Header: t("users.fields.firstName"),
        Cell: ({ row }) => this.editableContent("firstname", row)
      },
      {
        Header: t("users.fields.lastName"),
        Cell: ({ row }) => this.editableContent("lastname", row)
      },
      {
        Header: t("users.fields.company"),
        Cell: ({ row }) => this.editableContent("company", row)
      },
      {
        Header: t("users.fields.stat"),
        Cell: ({ row }) => {
          return (
            <span
              role="button"
              className="pointed"
              onClick={() => this.toggleModal(row._original.id)}
            >
              <u>here</u>
            </span>
          );
        }
      }
    ];
  }

  toggleModal = (currentSelected = null) => {
    this.setState(prevState => ({
      ...prevState,
      stat: { currentSelected, modal: !prevState.stat.modal }
    }));
  };
  render() {
    const { users } = this.props;
    const { makeConfirm, stat } = this.state;
    return (
      <div>
        <div className="marged-left marged-top marged-bottom flex-row flex-center flex-center-items">
          <SublimeSwitch
            onChange={e => this.setState({ makeConfirm: e.target.checked })}
            checked={makeConfirm}
            name="makeConfirm"
            id="makeConfirm"
            className="marged-right"
          />
          <label htmlFor="makeConfirm">confirm before update ?</label>
        </div>
        <ReactTable
          data={users}
          columns={this.columnsTemplate()}
          className="-striped -highlight"
          defaultPageSize={10}
        />
        <Modal centered isOpen={stat.modal} toggle={this.toggleModal}>
          <ModalBody>
            <Stat inModal userId={stat.currentSelected} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.allUsers
});

const mapDispatchToProps = {
  fetchUsers,
  updateUser,
  fetchRoles
};

// @ts-ignore
export default withTranslation(["admin"])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index)
);
