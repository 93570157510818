export function toggleMenu() {
  const menu = document.getElementById('sublime-menu');
  if (menu.classList.contains('sublime-menu-close')) {
    menu.classList.remove('sublime-menu-close');
  } else {
    menu.classList.add('sublime-menu-close');
  }
}
export function toggleSubMenu(onlyClear = false) {
  const allUl = document.querySelectorAll('ul');
  for (let i = 0; i < allUl.length; i++) {
    allUl[i].classList.remove('sublime-submenu-active');
  }
  if (!onlyClear) {
    setTimeout(() => {
      const activeMenu = document.getElementsByClassName('sublime-menu-item-active')[0];
      if (activeMenu) {
        const list = activeMenu.querySelector('ul');
        if (list) {
          list.classList.add('sublime-submenu-active');
        }
      }
    }, 50);
  }
}
export function setActiveSubMenu() {
  const allLi = document.querySelectorAll('li');
  for (let i = 0; i < allLi.length; i++) {
    allLi[i].classList.remove('sublime-submenu-item-active');
    if (allLi[i].children[0] && allLi[i].children[0].href === window.location.href) {
      allLi[i].classList.add('sublime-submenu-item-active');
    }
  }
}