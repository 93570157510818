import {
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  SUCCESS_MESSAGE,
  INFO_MESSAGE,
  CLEAR_MESSAGES
} from '../types/app';

export function successMessage(message) {
  return {
    type: SUCCESS_MESSAGE,
    payload: message
  };
};
export function infoMessage(message) {
  return {
    type: INFO_MESSAGE,
    payload: message
  };
};
export function warningMessage(message) {
  return {
    type: WARNING_MESSAGE,
    payload: message
  };
};
export function errorMessage(message) {
  return {
    type: ERROR_MESSAGE,
    payload: message
  };
};
export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
    payload: null
  };
};
