import { createStore, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import LocalFtorage from 'localforage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import allReducer from './reducers';

const persistConfig = {
  key: 'sublime3d-storage-key-v1.7',
  storage: LocalFtorage,
  blacklist: ['app', 'projects', 'posts', 'sublimeProjects', 'stats', 'offers'],
  whitelist: ['auth', 'user']
};

const persistedReducer = persistReducer(persistConfig, allReducer);

const store =
  // @ts-ignore
  process.env.NODE_ENV === 'production'
    ? createStore(persistedReducer, applyMiddleware(thunk))
    : createStore(persistedReducer, applyMiddleware(thunk, logger));

export default store;
