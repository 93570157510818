import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ResetPassword from './EditPassword';
import AlertMessage from '../../../../shared/messages/Alert';
import SublimeHeader from '../../../../shared/SublimeHeader';
function index({ t, history }) {
  return (
    <div className="layout-margin">
      <Toast>
        <ToastHeader>
          <SublimeHeader title={t('title')} />
        </ToastHeader>
        <ToastBody>
          <div className="flex-center marged-bottom">
            <AlertMessage />
          </div>
          <ResetPassword history={history} />
        </ToastBody>
      </Toast>
    </div>
  );
}

export default withTranslation('passwordReset')(index);
