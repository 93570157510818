import React from 'react';
import { MdInsertDriveFile, MdFolder } from 'react-icons/md';
// @ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Be CAREFULL Lazy load make an 404 request on each load
import 'react-lazy-load-image-component/src/effects/blur.css';
// @ts-ignore
import variables from 'exalt3d-sublime-ui/src/assets/styles/variables.scss';
import './FileItem.scss';
const iconSize = 100;
const imagesExtentions = ['jpg', 'jpeg', 'webp', 'gif', 'png'];
export default function FileItem({
  name = '',
  onClick = () => {},
  folder = {},
  isFolder = true
}) {
  const Icon = () => {
    if (!isFolder) {
      if (imagesExtentions.includes(name.split('.').pop())) {
        return (
          <LazyLoadImage
            alt={name}
            width="auto"
            height={iconSize}
            src={`${folder.baseUrl}/${name}`}
            effect="blur"
          />
        );
      } else {
        return (
          <MdInsertDriveFile color={variables.gold} size={iconSize - 20} />
        );
      }
    } else {
      return <MdFolder color={variables.darkGrey} size={iconSize} />;
    }
  };
  return (
    <div
      className="sublime-file-container flex-column flex-start flex-center-items marged-left marged-bottom pointed"
      role="button"
      onClick={() =>
        isFolder ? onClick() : window.open(`${folder.baseUrl}/${name}`)
      }
    >
      <div>{Icon()}</div>
      <div>
        <p
          className="sublime-file-item text-center"
          style={{ opacity: isFolder ? 1 : 0.5 }}
        >
          {folder.named_nomenclatures && folder.named_nomenclatures[name]
            ? folder.named_nomenclatures[name].split('->').map(levelName => (
                <span key={levelName}>
                  {levelName}
                  <br />
                </span>
              ))
            : name}
        </p>
      </div>
    </div>
  );
}
