// @ts-nocheck
import i18n from "../../../i18n";
export default {
  account: [
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.account.profile,
      path: "profile",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.subscriptions
          .package,
      path: "offers",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.account.stats,
      path: "stats",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.account.billing,
      path: "billing",
      confirm: false
    }
  ],
  projects: [
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.projects
          .inProgress,
      path: "in-progress",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.projects
          .availables,
      path: "availables",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.projects
          .archives,
      path: "archives",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.projects.new,
      path: "new",
      confirm: true
    }
  ],
  library: [
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.library
          .downloads,
      path: "downloads",
      confirm: false
    }
  ],
  api: [
    {
      label: i18n.store.data[i18n.language].dashboard.menu.subMenus.api.install,
      path: "install",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.api
          .documentation,
      path: "documentation",
      confirm: false
    }
  ],
  admin: [
    {
      label: i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.users,
      path: "users",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.projects,
      path: "projects",
      confirm: false
    },
    {
      label: i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.posts,
      path: "posts",
      confirm: false
    },
    {
      label:
        i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.offers,
      path: "offers",
      confirm: false
    },
    {
      label: i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.logs,
      path: "logs",
      confirm: false
    },
    {
      label: i18n.store.data[i18n.language].dashboard.menu.subMenus.admin.statistics,
      path: "statistics",
      confirm: false
    }
  ]
};
